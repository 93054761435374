import { KeyboardArrowDown, Search } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Container, Divider, InputAdornment, Menu, MenuItem, Pagination, Stack, TextField, Toolbar, Typography, styled } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useState } from 'react'
import InvestorItem from './investorItem'
import base from '../../api/apis'
import { useDispatch } from 'react-redux'
import Lottie from 'react-lottie'
import LoadAnim from '../../assets/loading.json'
import startCase from 'lodash/startCase'
import PaperImg from '../../assets/paper.png'

const SuggestionBox = styled(Box)({
    overflowY: 'scroll', 
    position:'absolute',
    zIndex: 1, height: 'fit-content',
    borderRadius: '0 0 8px 8px',
    boxShadow: '0 2px 10px rgba(0,0,0,10%)',
    width: '97%', 
    background: '#fff', 
    padding: '1rem',
    paddingTop: '3rem',
    top: '50%'
})

const SuggestionList = ({address, type, owner, saleDate, onClick, lastSeller}) => {
    console.log(lastSeller)
    return (
        <Box p={2} onClick={onClick} borderRadius={2} sx={{':hover': { bgcolor: grey[100]}, cursor: 'pointer'}}>
            <Typography textAlign={'left'} mb={.5} sx={{fontSize: '1.1rem', fontWeight: 300}}>{address}</Typography>
            <Stack direction={'row'} gap={'.6rem'}  alignItems={'center'}>
                <Typography variant='body2' color={'GrayText'}>{startCase(type?.toLowerCase())}</Typography>
                <Divider flexItem orientation='vertical' />
                <Typography variant='body2' color={'GrayText'} noWrap>Sold by {lastSeller}</Typography>
                <Divider flexItem orientation='vertical' />
                <Typography variant='body2' color={'GrayText'}>Sold on {saleDate}</Typography>
            </Stack>
        </Box>
    )
}

const InvestorList = () => {
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const [address, setAddress] = useState('')
    const [searchFilter, setSearchFilter] = useState('address')
    const [data, setData] = useState()
    const [suggested, setSuggested] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [pageCount, setPageCount] = useState(null);
    const [page, setPage] = useState(1);
    const open = Boolean(anchorEl)
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [timer, setTimer] = useState(null)

    const searchListings = async(pg, searchAddress) => {
        if(address === '') return
        console.log(searchFilter)
        try {
            setLoad(true)
            const {data: res} = searchFilter === 'address' ? 
            await base.get(`farm/investors?${ searchAddress ? 'address=' + searchAddress : 'suggestion=' + address}&page=${pg}`) 
            : await base.get(`farm/investors?${ searchAddress ? 'address=' + searchAddress : 'zip_code=' + address}&page=${pg}`)
            // console.log(res)
            setData(res?.results?.features)
            setSuggested([])
            setShowSuggestions(false)
            setPageCount(res?.count)
            setPage(pg)
            setLoad(false)
            
        } catch (error) {
            setLoad(false)
            dispatch({type: 'ERROR', payload: 'Sorry something went wrong. Try again'})
        }
    }

    const filterHandler = (val) => {
        setAnchorEl(null)
        setSuggested([])
        setAddress('')
        setSearchFilter(val)
    }

    const handleChange = async(e, page) => {
        window.scrollTo({top: 0, behavior: 'smooth'})
        await searchListings(page)
    }

    const clickAway = (e) => {
		window.addEventListener('click', function(e){   
			if (document.getElementById('suggestionbox').contains(e.target)){
			  setShowSuggestions(true)
			} else{
			  setShowSuggestions(false)
			}
		  });
	}

    const onSearchHandler = async(e) => {
        if(e?.target?.value !== ''){   
            setAddress(e.target.value)
            // clear timer 
            clearTimeout(timer)
            
            // new timer
            const newTimer = setTimeout( async() => {
                setLoad(true)
                const { data: res} =  searchFilter === 'address' ? await base.get(`farm/investors?suggestion=${e.target.value}`) : await base.get(`farm/investors?zip_code=${e.target.value}`)
                console.log(res)
                setLoad(false)
                setSuggested(res?.results?.features)
                setShowSuggestions(true)
            }, 500);

            // save timerID
            setTimer(newTimer)

        }else{
            setAddress('')
            setSuggested([])
        }

	}

  return (
    <Box>
        {/* HeadBanner */}
        <Box p={'3rem 0'} sx={{bgcolor: grey[200]}}>
            <Toolbar />
            <Container sx={{textAlign: 'center'}}>
                <Typography variant='h4'>Find your next property listing</Typography>
                <Typography mb={4} color={'GrayText'}>Provide an address or zipcode to make a search for properties</Typography>

                {/* Search */}
                <Box position='relative' >
                    <TextField id='suggestionbox' variant='outlined' type={ searchFilter === 'address' ? 'text' : 'number' } fullWidth 
                        onChange={e => onSearchHandler(e)} value={address}
                        sx={{ zIndex:2, '& .MuiOutlinedInput-root': {
                            bgcolor: '#fff',
                            borderRadius: '100px'
                        }}} 
                        onBlur={clickAway}
                        onFocus={()=> setShowSuggestions(true)}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>
                            <Button variant='outlined' onClick={e => setAnchorEl(e.currentTarget)} 
                                endIcon={<KeyboardArrowDown fontSize='small' sx={{fontSize: '.9rem', }} />} 
                                sx={{fontWeight: 400, color: 'GrayText', textTransform: 'none', borderRadius: '50px', mr: 2, borderColor: 'lightgrey'}}>{startCase(searchFilter)}</Button>
                            <Menu open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                                <MenuItem onClick={()=> filterHandler('address')} sx={{fontSize: '1rem'}}>Address</MenuItem>
                                <MenuItem onClick={()=> filterHandler('zip')} sx={{fontSize: '1rem'}}>Zip Code</MenuItem>
                            </Menu>
                            <Search fontSize='small' sx={{color: grey[500]}} />
                            </InputAdornment>,
                            endAdornment: <InputAdornment position='end'>
                                <LoadingButton onClick={() => searchListings(1)} sx={{borderRadius: '50px'}} variant='contained' color='secondary' loading={load} disableRipple disableElevation>Search</LoadingButton>
                            </InputAdornment>
                        }} 
                        placeholder={ searchFilter === 'address' ? 'Enter property address' : 'Enter zip code' }
                    />
                    {
                       ( showSuggestions && suggested?.length > 0 ) &&
                            <SuggestionBox >
                            {
                                suggested?.length > 0 ? suggested?.slice(0, 5)?.map(el => {
                                    return (
                                            <SuggestionList key={el?.id} onClick={()=>searchListings(1, el?.properties?.site_addr)}
                                                address={startCase(el?.properties?.site_addr?.toLowerCase()) + ' ' + startCase(el?.properties?.site_county?.toLowerCase()) + ' ' +  startCase(el?.properties?.site_city?.toLowerCase()) + ' ' + el?.properties?.site_state + ', ' + el?.properties?.site_zip}
                                                lastSeller={el?.properties?.last_market_sale_seller_name}
                                                owner={el?.properties?.owner_name_1}
                                                type={el?.properties?.use_code_std_ctgr_desc_lps}
                                                saleDate={new Date(el?.properties?.last_market_sale_date_transfer).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric'})}
                                            />
                                    )

                                }):
                                <Typography>No results found</Typography>
                            }
                            </SuggestionBox>
                    }
                </Box>
            </Container>
        </Box>

        {
            load ? 
            <Box mt={'3rem'} height={'40vh'}>
                <Lottie width={'30%'} height={'auto'} options={{
                    autoplay: true,
                    loop: true,
                    animationData: LoadAnim,
                }} speed={1.5} />
                <Typography textAlign={'center'}>Loading. Please wait...</Typography>
            </Box>
            :
            data && data?.length > 0 ?
            <Box py={'3rem'}>
                <Container>
                    <Typography variant='h6' sx={{fontWeight: 400, fontSize: '1.1rem'}} mb={3}>Showing {data?.length || 0} listings</Typography>
                    {
                        data?.map(el => {
                            return (
                                <InvestorItem 
                                    key={el?.id} 
                                    searchTerm={address}
                                    address={startCase(el?.properties?.site_addr?.toLowerCase()) + ' ' + startCase(el?.properties?.site_county?.toLowerCase()) + ' ' +  startCase(el?.properties?.site_city?.toLowerCase()) + ' ' + el?.properties?.site_state + ', ' + el?.properties?.site_zip}
                                    owner={el?.properties?.owner_name_1}
                                    lastSeller={el?.properties?.last_market_sale_seller_name}
                                    type={el?.properties?.use_code_std_ctgr_desc_lps}
                                    bed={el?.properties?.bedrooms}
                                    bath={el?.properties?.total_baths}
                                    area={el?.properties?.lot_size_area}
                                    year={el?.properties?.yr_blt}
                                    salePrice={el?.properties?.last_market_sale_val_transfer || 0}
                                    saleDate={new Date(el?.properties?.last_market_sale_date_transfer).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric'})}
                                />

                            )
                        })
                    }

                    <Box mt={4} mb={4} display='flex' justifyContent={'flex-end'}>
                        <Pagination sx={{
                            '& ul': {
                                marginLeft: 'auto'
                            }
                        }} color='secondary' 
                        page={page}
                        count={Math.ceil(pageCount / 10)} 
                        onChange={handleChange}
                        variant="text" shape="rounded" />
                    </Box>
                    
                </Container>    
            </Box>
            :
            <Box height={'50vh'} textAlign={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                <img src={PaperImg} width={'10%'} alt='nolisting' />
                <Typography mt={3} sx={{fontSize: '1.6rem'}}>No Property Listing</Typography>
                <Typography variant='body1' color={'GrayText'}>Search to display listings</Typography>
            </Box>

        }
    </Box>
  )
}

export default InvestorList