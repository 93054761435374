import React, { useEffect, useRef, useState } from "react";
import "./brokerage.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "./brokerage.css";
import heroBg from "../../assets/brokerage/herobg.jpg";
import heroWoman from "../../assets/brokerage/Image (3).svg";
import registerImage from "../../assets/brokerage/Image (1).png";
import faqImage from "../../assets/brokerage/Image (1).svg";
import manWithHouse from "../../assets/brokerage/manWithHouse.png";
import manWithHouse2 from "../../assets/brokerage/Content.png";
import checkIcon1 from "../../assets/brokerage/Check icon (1).png";
import twoPaddies from "../../assets/brokerage/Image (4).png";
import smallHouse from "../../assets/brokerage/Image (5).png";
import featuredIcon from "../../assets/brokerage/icons/Featured icon.svg";
import featuredIcon1 from "../../assets/brokerage/icons/Featured icon (1).svg";
import featuredIcon2 from "../../assets/brokerage/icons/Featured icon (2).svg";
import featuredIcon3 from "../../assets/brokerage/icons/Featured icon (3).svg";
import featuredIcon4 from "../../assets/brokerage/icons/Featured icon (4).svg";
import featuredIcon5 from "../../assets/brokerage/icons/Featured icon (5).svg";
import featuredIcon6 from "../../assets/brokerage/icons/Featured icon (6).svg";
import featuredIcon7 from "../../assets/brokerage/icons/Featured icon (7).svg";
import featuredIcon8 from "../../assets/brokerage/icons/Featured icon (8).svg";
import featuredIcon9 from "../../assets/brokerage/icons/Featured icon (9).svg";
import greenCheckedIcon from "../../assets/brokerage/icons/Icon.svg";
import image2 from "../../assets/brokerage/icons/Image (2).svg";
import dealAnalyzerLogo from "../../assets/brokerage/icons/BPO Homes Deal Analyzer.svg";

import { MuiTelInput } from "mui-tel-input";
import { useTheme } from "@emotion/react";

import anime from "animejs";
import { ArrowUpward } from "@mui/icons-material";
import { ResponsiveDesign } from "./ResponsiveDesign";
import { getBrokerageToken } from "../../actions";
import securedBrokerageBaseURL from "./Apis";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);
//animated text comp
const AnimatedText = () => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  const resDesign = ResponsiveDesign();
  useEffect(() => {
    if (pageLoaded) {
      anime
        .timeline({ loop: true })
        .add({
          targets: ".ml9 .letter",
          scale: [0, 1],
          duration: 1500,
          elasticity: 600,
          delay: (el, i) => 45 * (i + 1),
        })
        .add({
          targets: ".ml9",
          opacity: 0,
          duration: 1000,
          easing: "easeOutExpo",
          delay: 1000,
        });
    }
  }, [pageLoaded]);

  const text = "Real Estate License?";
  const wrappedText = text.split("").map((char, index) => (
    <span
      id="animatedText"
      key={index}
      style={{
        fontFamily: "roboto",
        fontWeight: 700,
        fontSize: resDesign.textSizes,
      }}
      className={char === " " ? "" : "letter"}
    >
      {char}
    </span>
  ));

  return <div className="ml9">{wrappedText}</div>;
};

// animated comp
const AnimatedComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const resDesign = ResponsiveDesign();
  const revealRefs = useRef([]);
  revealRefs.current = [];
  const [phone, setPhone] = React.useState("");
  const swiperRef = useRef(null);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  // getBrokerageAccessToken
  useEffect(() => {
    (async () => await getBrokerageToken())();
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    revealRefs.current.forEach((elem) => {
      gsap.set(elem, { autoAlpha: 0 }); // Initial hide

      ScrollTrigger.create({
        trigger: elem,
        start: "top center+=000",
        end: "bottom center-=100",
        // markers: true,
        onEnter: () => animateFrom(elem),
        onEnterBack: () => animateFrom(elem, -1),
        onLeave: () => animateFrom(elem),
      });
    });
  }, []);

  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  const animateFrom = (elem, direction = 1) => {
    const x = elem.classList.contains("gs_reveal_fromLeft")
      ? -100
      : elem.classList.contains("gs_reveal_fromRight")
        ? 100
        : 0;
    const y = direction * 100;
    gsap.fromTo(
      elem,
      { x, y, autoAlpha: 0 },
      {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  };
  const navigate = useNavigate();
  const scrollToForm = (e) => {
    e.stopPropagation();
    navigate("/internPrice");
    // swiperRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const submitForm = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await securedBrokerageBaseURL.get(
        "/partials/create-list/?category=agent_type"
      );
      const agentTypes = data?.results;
      const internAgent = agentTypes.filter(
        (agent) => agent.name === "internship"
      );
      await securedBrokerageBaseURL.post("/lead-distribution/lead-agents/", {
        firstName,
        lastName,
        userEmail: email,
        phoneNumber: phone,
        agentType: internAgent?.[0].id,
      });

      setPhone("");
      setEmail("");
      setLastName("");
      setLastName("");
      setLoading(false);
      dispatch({
        type: "SUCCESS",
        payload: "Great🎉🎉, Form submitted successfully",
      });
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: "Sorry, something went wrong. Please try again",
      });
      setLoading(false);
    }
  };

  return (
    <Box sx={{ overflow: "hidden", }}>

      <div className="features">
        <Grid
          container
          sx={{
            position: "relative",
            justifyContent: isMobile ? "center" : null,
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${heroBg})`,
              backgroundSize: isMobile ? "100% 100%" : "60% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 0.5,
              zIndex: -1,
            },
          }}
        >
          <Grid
            sm={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            md={6}
            item
          >
            <Box ref={addToRefs}>
              <Typography fontWeight={700} variant={isMobile ? "h4" : "h2"}>
                Want to get your
              </Typography>
              <AnimatedText />
              <Typography variant={isMobile ? "h5" : "h4"} mt={4}>
                Join the BPO Homes Realty <br />
                Internship Program
              </Typography>
              <Button
                fullWidth={isMobile ? true : false}
                variant="contained"
                sx={{
                  width: !isMobile ? "12rem" : null,
                  mt: 4,
                  textTransform: "none",
                }}
                color="secondary"
                size="large"
                onClick={scrollToForm}
              >
                Enroll Now
              </Button>
            </Box>
          </Grid>
          <Grid
            className="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft"
            ref={addToRefs}
            sm={10}
            md={6}
            mt={isMobile ? 4 : ""}
            height={resDesign.imageHeight}
            item
          >
            <img src={heroWoman} alt="" height={"100%"} />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobile ? 0 : 10, mx: isMobile ? 0 : 8 }}>
          <Grid container sx={{ width: "100vw", overflow: "hidden" }}>
            <Grid
              sm={10}
              sx={{
                backgroundColor: "#F9FAFB",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              md={6}
              item
            >
              <Box sx={resDesign.generalStyle}>
                <Typography
                  ref={addToRefs}
                  variant="h2"
                  fontSize={resDesign.textSizes}
                >
                  <span style={{ fontWeight: 700 }}>Get Started Now</span>
                </Typography>
                <Typography mt={2} variant={resDesign.bodyText}>
                  <span style={{ fontWeight: 700 }}>
                    BPO Homes will Reimburse you 100% of your
                    <br />
                    Education Fee at the closing of your 1st Real Estate
                    Transaction.
                  </span>
                </Typography>
                <Typography variant={resDesign.bodyText} mt={4}>
                  Your Real Estate Education is FREE!!
                </Typography>
              </Box>
            </Grid>
            <Grid
              ref={addToRefs}
              sm={10}
              md={6}
              sx={{

                backgroundImage: `url(${manWithHouse})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
              height={resDesign.imageHeight}
              item
            >

            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2, overflow: "hidden" }}>
            <Grid
              sm={10}
              sx={{
                display: "flex",
                mt: 5,
                alignItems: "center",
                flexDirection: "column",
              }}
              md={6}
              item
            >
              <Box ref={swiperRef} sx={resDesign.generalStyle} mb={5}>
                <Typography ref={addToRefs} fontWeight={700} variant="h4">
                  Get Started Now
                </Typography>
                <Typography
                  ref={addToRefs}
                  variant={resDesign.bodyText}
                  fontWeight={500}
                  mt={2}
                >
                  Program Fee of your Education Fee at the closing of your{" "}
                  <br />
                  1st Real Estate Transaction so the Kaplan Real Estate
                  <br /> education is FREE!!
                </Typography>
                <form>
                  <FormGroup>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 4,
                        mt: 2,
                        flexWrap: isMobile ? "wrap" : "nowrap",
                      }}
                    >
                      <FormControl style={{ width: isMobile ? "90%" : "50%" }}>
                        <InputLabel htmlFor="component-outlined">
                          First name
                        </InputLabel>
                        <OutlinedInput
                          onChange={(e) => setFirstName(e.target.value)}
                          id="component-outlined"
                          label="First name"
                          value={firstName}
                        />
                      </FormControl>
                      <FormControl style={{ width: isMobile ? "90%" : "50%" }}>
                        <InputLabel htmlFor="component-outlined">
                          Last name
                        </InputLabel>
                        <OutlinedInput
                          onChange={(e) => setLastName(e.target.value)}
                          id="component-outlined"
                          label="Last name"
                          value={lastName}
                        />
                      </FormControl>
                    </Box>

                    <FormControl
                      style={{ width: isMobile ? "90%" : "" }}
                      sx={{ mt: 4 }}
                    >
                      <InputLabel htmlFor="component-outlined">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        onChange={(e) => setEmail(e.target.value)}
                        id="component-outlined"
                        type="email"
                        placeholder="you@company.com"
                        label={"Email"}
                        value={email}
                      />
                    </FormControl>
                    <FormControl sx={{ mt: 4 }}>
                      {/* <InputLabel htmlFor="component-outlined">Phone Number</InputLabel> */}
                      <MuiTelInput
                        style={{ width: isMobile ? "90%" : "" }}
                        defaultCountry="US"
                        value={phone}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <LoadingButton
                      loading={loading}
                      onClick={submitForm}
                      style={{ width: isMobile ? "90%" : "" }}
                      fullWidth={isMobile ? false : true}
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 4 }}
                    >
                      Get Started
                    </LoadingButton>
                  </FormGroup>
                </form>
              </Box>
            </Grid>

            <Grid sm={10} md={6} ref={addToRefs} item>
              <img src={registerImage} alt="" height={"100%"} width={"100%"} />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              position: "relative",
              width: "100vw",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${heroBg})`,
                backgroundSize: isMobile ? "100% 100%" : "70% 100%",
                backgroundRepeat: "no-repeat",
                opacity: 0.3,
                zIndex: -1,
              },
            }}
          >
            <Grid
              ref={addToRefs}
              sm={10}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              md={8}
              item
            >
              <Box ref={addToRefs} sx={resDesign.generalStyle}>
                <Typography fontWeight={700} variant={isMobile ? "h4" : "h2"}>
                  Learn to Sell Real Estate the <br />
                  Right way
                </Typography>
                <Typography
                  ref={addToRefs}
                  variant={isMobile ? "h6" : "h5"}
                  mt={4}
                >
                  We Teach You How to Sell Real Estate the Right way from the
                  very beginning & <br />
                  provide you with all of the Following and more…
                </Typography>
                <Box mt={4}>
                  {kaplanArray.map((m) => (
                    <Typography
                      mt={2}
                      variant={resDesign.bodyText}
                      style={{
                        display: "flex",
                        justifySelf: "center",
                        gap: 10,
                      }}
                    >
                      <img
                        src={checkIcon1}
                        height={resDesign.iconsSize}
                        alt=""
                      />
                      <span>{m}</span>
                    </Typography>
                  ))}
                </Box>
                <Typography
                  color={"secondary"}
                  onClick={scrollToForm}
                  sx={{
                    fontSize: 13,
                    cursor: "pointer",
                    fontWeight: 400,
                    ml: "2.4rem",
                    mt: "1rem",
                  }}
                >
                  Click Here to Enroll <ArrowUpward fontSize="13" />
                </Typography>
              </Box>
            </Grid>
            <Grid
              ref={addToRefs}
              sm={10}
              md={4}
              height={resDesign.imageHeight}
              item
            >
              <img src={manWithHouse2} alt="" height={"100%"} />
            </Grid>
          </Grid>
          <Grid
            container
            direction={isMobile ? "column-reverse" : "row"}
            sx={{ width: "100vw", position: "relative", overflow: "hidden" }}
          >
            <Grid
              sm={10}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              md={6}
              item
            >
              <Box ref={addToRefs} sx={resDesign.generalStyle}>
                <Typography variant="h4" fontWeight={700}>
                  Learn How to Analyze Deal with <br />
                  the Deal Analyzer Platform
                </Typography>
                <Typography mt={2} variant={resDesign.bodyText}>
                  Whether you have a team of 2 or 200, our shared team
                  <br /> inboxes keep everyone on the same page and in the loop.
                </Typography>
                <Box mt={4} ref={addToRefs}>
                  {dealAnalyzerArr.map((m) => (
                    <Typography
                      mt={2}
                      variant={resDesign.bodyText}
                      style={{
                        display: "flex",
                        justifySelf: "center",
                        gap: 10,
                      }}
                    >
                      <img
                        src={checkIcon1}
                        height={resDesign.iconsSize}
                        alt=""
                      />
                      <span>{m}</span>
                    </Typography>
                  ))}
                </Box>
                <Button
                  onClick={scrollToForm}
                  sx={{
                    fontSize: 13,
                    cursor: "pointer",
                    fontWeight: 400,
                    ml: "2.4rem",
                    mt: "1rem",
                  }}
                  style={{ textTransform: "none" }}
                  endIcon={<ArrowUpward />}
                  variant="outlined"
                  color="secondary"
                >
                  Enroll Now
                </Button>
              </Box>
            </Grid>

            <Grid
              ref={addToRefs}
              sm={10}
              md={6}
              height={resDesign.imageHeightForSmallImages}
              item
            >
              {" "}
              <div
                style={{
                  position: "absolute",
                  zIndex: 10,
                  left: "10%",
                  bottom: "10%",
                }}
              >
                <img
                  src={dealAnalyzerLogo}
                  style={{ height: isMobile ? "100px" : "100%" }}
                  alt=""
                />
              </div>
              <img src={image2} alt="" height={"100%"} />
            </Grid>
          </Grid>
          {/*  */}
          <Grid
            container
            sx={{
              width: "100vw",
              overflow: "hidden",
            }}
          >
            <Grid
              ref={addToRefs}
              sm={10}
              md={6}
              height={resDesign.imageHeightForSmallImages}
              item
            >
              <img src={twoPaddies} alt="" width={"100%"} />
            </Grid>
            <Grid
              sm={10}
              sx={{
                ml: isMobile ? "1rem" : "",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "#F9FAFB",
              }}
              md={6}
              item
            >
              <Box ref={addToRefs}>
                <Typography variant="h4" fontWeight={700}>
                  FREE Agent Advertising any City
                  <br /> of Agent’s Choice
                </Typography>
                <Typography mt={2} variant="h6">
                  An all-in-one customer service platform that helps you
                  <br /> balance everything your customers need to be happy.
                </Typography>
                <Box mt={4} ref={addToRefs}>
                  {freeAgent.map((m) => (
                    <Typography
                      mt={2}
                      variant={resDesign.bodyText}
                      style={{
                        display: "flex",
                        justifySelf: "center",
                        gap: 10,
                      }}
                    >
                      <img
                        src={checkIcon1}
                        height={resDesign.iconsSize}
                        alt=""
                      />
                      <span>{m}</span>
                    </Typography>
                  ))}
                </Box>
                <Button
                  onClick={scrollToForm}
                  sx={{
                    fontSize: 13,
                    cursor: "pointer",
                    fontWeight: 400,
                    ml: "2.4rem",
                    mt: "1rem",
                  }}
                  style={{ textTransform: "none" }}
                  endIcon={<ArrowUpward />}
                  variant="outlined"
                  color="secondary"
                >
                  Enroll Now
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction={isMobile ? "column-reverse" : "row"}
            sx={{
              width: "100vw",
              overflow: "hidden",
            }}
          >
            <Grid
              sm={10}
              sx={{
                ml: isMobile ? "1rem" : "",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              md={6}
              item
            >
              <Box ref={addToRefs}>
                <Typography variant="h4" fontWeight={700}>
                  Be an Investor, Not Just an Agent
                </Typography>
                <Typography mt={2} variant="h6">
                  THE ONLY Brokerage Pushing you to Become a Real <br />
                  Estate Investor$$ Not just a Typical Agent
                </Typography>
                <Box mt={4} ref={addToRefs}>
                  {investorAgent.map((m) => (
                    <Typography
                      mt={2}
                      variant={resDesign.bodyText}
                      style={{
                        display: "flex",
                        justifySelf: "center",
                        gap: 10,
                      }}
                    >
                      <img
                        src={checkIcon1}
                        height={resDesign.iconsSize}
                        alt=""
                      />
                      <span>{m}</span>
                    </Typography>
                  ))}
                </Box>
                <Button
                  onClick={scrollToForm}
                  sx={{
                    fontSize: 13,
                    cursor: "pointer",
                    fontWeight: 400,
                    ml: "2.4rem",
                    mt: "1rem",
                  }}
                  style={{ textTransform: "none" }}
                  endIcon={<ArrowUpward />}
                  variant="outlined"
                  color="secondary"
                >
                  Enroll Now
                </Button>
              </Box>
            </Grid>
            <Grid
              ref={addToRefs}
              sm={10}
              md={6}
              height={resDesign.imageHeightForSmallImages}
              item
            >
              <img src={smallHouse} alt="" width={"100%"} />
            </Grid>
          </Grid>
        </Box>
        <div style={{ backgroundColor: "#F9FAFB" }}>
          <Box
            ref={addToRefs}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Typography
              color={"secondary"}
              sx={{
                fontWeight: 500,
              }}
            >
              Benefits
            </Typography>
            <Typography variant="h4" fontWeight={600}>
              Why Intern at BPO Homes?
            </Typography>
            <Typography variant="h6" mt={2} fontWeight={500}>
              Launch your real estate career with BPO Homes! Gain hands-on
              experience, <br />
              develop essential skills, and build valuable connections in the
              industry.
            </Typography>
          </Box>
          <Grid
            container
            sx={{
              mt: 6,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              gap: 10,
            }}
          >
            {internScript.map((script) => (
              <Grid sm={10} md={3} key={script.heading}>
                <img src={script.icon} alt="" />
                <Typography variant="h6">{script.heading}</Typography>
                <Typography>{script.body}</Typography>
              </Grid>
            ))}
          </Grid>
          <Box mt={6}>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography
                color={"secondary"}
                sx={{
                  fontWeight: 500,
                }}
              >
                BPO Homes Perks
              </Typography>
              <Typography variant="h4" fontWeight={600}>
                Perks when you join BPO Homes
              </Typography>
              <Typography sx={{ fontSize: 15 }} mt={2} fontWeight={400}>
                Discover a world of opportunities at BPO Homes! These are just
                a few of the many benefits you'll experience as an intern.
                From hands-on <br />
                learning to mentorship from industry experts, we're committed
                to providing you with the tools and support needed to succeed
                in your real
                <br /> estate career.
              </Typography>
            </Box>
            <Grid
              container
              sx={{
                display: "flex",
                pl: isMobile ? "1rem" : "",
                mt: 6,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "start",
                gap: 2,
              }}
            >
              <Grid item sm={10} md={5}>
                {dataPoints.slice(0, 14).map((m) => (
                  <Typography mt={2} style={{ display: "flex", gap: 4 }}>
                    <img src={greenCheckedIcon} alt="" />{" "}
                    <Typography variant={isMobile ? "body2" : ""}>
                      {m}
                    </Typography>
                  </Typography>
                ))}
              </Grid>
              <Grid item sm={10} md={5}>
                {dataPoints.slice(15, 28).map((m) => (
                  <Typography style={{ display: "flex", gap: 4 }} mt={2}>
                    <img src={greenCheckedIcon} alt="" />
                    <Typography variant={isMobile ? "body2" : ""}>
                      {" "}
                      {m}
                    </Typography>
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Box>{" "}
          <Box mt={6} sx={{ backgroundColor: "#F9FAFB" }}>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography
                color={"secondary"}
                sx={{
                  fontWeight: 500,
                }}
              >
                Support
              </Typography>
              <Typography variant="h4" fontWeight={600}>
                Frequently asked questions
              </Typography>
              <Typography sx={{ fontSize: 15 }} mt={2} fontWeight={400}>
                Everything you need to know about the internship programme.
                Can’t find the answer <br />
                you’re looking for? Please chat to our friendly team.
              </Typography>
            </Box>
            <Grid
              container
              sx={{
                display: "flex",
                pl: isMobile ? "1rem" : "",
                mt: 6,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "start",
                gap: 2,
              }}
            >
              <Grid item sm={10} md={5}>
                {faq.map((m) => (
                  <Typography
                    style={{
                      display: "flex",
                      gap: 10,

                      alignItems: "start",
                    }}
                    mt={2}
                  >
                    <img src={m.icon} alt="" />
                    <Box>
                      <Typography variant="h6" mt={2}>
                        {" "}
                        {m.question}
                      </Typography>
                      <Typography mt={1}> {m.answer}</Typography>
                    </Box>
                  </Typography>
                ))}
              </Grid>
              <Grid item sm={10} md={5}>
                <img src={faqImage} alt="" />
              </Grid>
            </Grid>
          </Box>

        </div>
      </div>
    </Box>
  );
};

export default AnimatedComponent;

const kaplanArray = [
  "Real Estate Education Included",
  "Robust Step by Step Contracts & Disclosure Trainings",
  "Agent Marketing Trainings",
  "Learn to Farm the Right Way",
  "Piggy Backing (Piggyback Marketing Technique)",
  "Open House Trainings",
  "Real Estate Investment Training (Learn to Become a Real Estate Investor Not Just an Agent)",
];

const dealAnalyzerArr = [
  "Analyze Flips",
  "Analyze Remodels",
  "Analyze Additions",
  "Analyze Rentals",
  "Analyze Development Projects",
];

const freeAgent = [
  "Agent Farming Marketing (Select BPO Realty Packages Apply)",
  "We Email Sellers",
  "We Text Sellers",
  "We Even Call Sellers on your Behalf as a part of your Team!!",
];
const investorAgent = [
  "Learn how to become A Real Estate Investor!",
  "Learn to Create Passive Income for the rest of your Life!",
  "Learn to Leverage Other People's Money!",
  "Your Goal is NOT to be a Real Estate AGENT Your Entire LIFE..",
];

const internScript = [
  {
    icon: featuredIcon,
    heading: "Gain hands-on experience in the fast-paced world of real estate",
    body: "Shadow top agents, learn the ropes of listing and selling properties, and get a feel for the dynamic market.",
  },
  {
    icon: featuredIcon1,
    heading: "Master the skills to become a successful agent or broker",
    body: "Hone your negotiation, marketing, and client communication skills through real-world experience, preparing you to excel in the industry.",
  },
  {
    icon: featuredIcon2,
    heading: "Build your network and gain valuable industry connections",
    body: "Develop relationships with potential clients, lenders, and other key players, giving you a head start in your real estate career.",
  },
  {
    icon: featuredIcon3,
    heading: "Get exposure to cutting-edge real estate tools and technologies",
    body: "Learn about the latest digital marketing strategies and property management software, ensuring you're equipped for success in the modern real estate landscape.",
  },
  {
    icon: featuredIcon4,
    heading:
      "Develop valuable professional skills - Communication, Empathy, etc. ",
    body: "Enhance your communication, teamwork, and problem-solving abilities through practical application in a professional setting.",
  },
  {
    icon: featuredIcon5,
    heading: "Competitive stipend and potential for future employment",
    body: "Earn compensation for your hard work while gaining a competitive edge for securing a coveted position within our company after the internship.",
  },
];

const dataPoints = [
  "Unlimited Leads",
  "Free BPO Tech AI Tech",
  "Free Lofty CRM +Lofty AI",
  "Investor List Organizer Tool",
  "Free Luxury Investor Website",
  "Revenue Share (Coming soon 2025)",
  "Free Fully Customizable IDX Agent Website",
  "Free Social Media Management (Coming Soon 2025)",
  "Free Social Media Advertisement (Coming Soon 2025)",
  "Farm Marketing Includes Yearly Custom Designed MMS Text to Sellers",
  "Free BPO Report Creation (Agents Can Dictate Price Charged for BPO Reports)",
  "Company Stock Offerings (Only Applicable when Rounds are Open)",
  "Farm Marketing Includes Yearly Custom Designed Emails to Sellers",
  "Cash Flow 4U Consistent Buyer for to Back your Real Estate Investment Deals $$$",
  "Free Deal Analyzer",
  "Transaction Coordinators",
  "Free Digital Business Card",
  "Free Investor Agent Training",
  "Free Coaching & Mentorship for Life",
  "Free Power Coaching with Joban Brown",
  "Full Training Center Contracts on BPO Tech",
  "Free Social Media Management (Coming Soon 2025)",
  "Free Farm Marketing for Realty Packages $199.00 and above",
  "Farm Marketing Includes Yearly Direct Call Center Calling to Sellers",
  "Free CE Courses (In Applicable States and Agent Tenure Rules Apply)",
  "Flat Fee Commission Rates starting at ONLY $199.00 Per Transaction",
  <>
    Learn to Wholesale through a Wholesaler and Earn up to 6 commissions on{" "}
    <br /> a Single Transaction
  </>,
  "And More!",
];

const faq = [
  {
    question: "Who is this internship program for?",
    answer: (
      <>
        This internship program is designed for aspiring real estate and <br />{" "}
        brokers who are passionate about the industry and eager to learn from
        <br /> experienced professionals.
      </>
    ),
    icon: featuredIcon6,
  },
  {
    question: "What kind of experience will I gain?",
    answer: (
      <>
        You'll gain hands-on experience in various aspects of real estate,
        <br /> including property listing and marketing, client market <br />{" "}
        research, and potentially assisting with open houses and showings.
      </>
    ),
    icon: featuredIcon7,
  },
  {
    question: "What skills will I develop?",
    answer: (
      <>
        You will help develop essential skills such as negotiation,
        <br /> marketing, client communication, market analysis, and
        problem-solving.
      </>
    ),
    icon: featuredIcon8,
  },
  {
    question: "How can I apply?",
    answer: (
      <>Click the 'Enroll NOW' buttons to join. Oh Yes! It is that simple.</>
    ),
    icon: featuredIcon9,
  },
];
