export const invertorsScript = [
    {
        "heading": "Building Successful Real Estate Agents & Investors",
        "body": [
            "Learn More",
            "Only Brokerage Teaching Agents How to Become Real Estate Investors",
            "Not Just Your Standard Agent"
        ]
    },
    {
        "heading": "Learn to Become a Successful Real Estate Investor with BPO Homes",

        "body": [
            "Learn Construction & Investing with Live Real Estate Power Coaching by Joban Brown",
            "Learn the 4/2 Rule. Sell 4 Properties and use your commission in order to Buy 2  Investment properties between 25k and 50k in the Midwest or Back East that Cash Flow ",
            "$500 to 800 per Month reaching a Income minimum residual Income Goal of 10k per Month of Cash Flow income for the Rest of Your Life"
        ]
    },
    {
        "heading": "Unlimited Leads",
        "body": [
            "Pre Qualified Leads (Select Packages Apply)",
            "Farm Leads(Select Packages Apply)",
            "Divorce (Included Free in Every Package)",
            "Probate (Included Free in Every Package)",
            "PreForeclosure (Notice of Default) (Included Free in Every Package)",
            "Expired (Included Free in Every Package)",
            "Canceled (Included Free in Every Package)",
            "Withdrawns (Included Free in Every Package)"
        ]
    },
    {
        "heading": "BPO Realty Commission Caps Starting at Only 2k",
        "body": [
            "Save your Money with the Lowest Cost Brokerage in the Country",
            "Commission Caps Starting as low as 2k",
            "E&0 and Yearly Training Fee Not Included",
        ]
    },
    {
        "heading": "Access to Technology that helps you Generate Listings, BPO Reports and More..!!",
        "body": [
            "Create BPO Reports Broker Price Opinions on Properties",
            "NO More FREE CMA’s BPO Today",
            "Unlimited Life Triggering Event Seller Listing Leads",
            "Farming Seller Leads"
        ]
    },
    {
        heading: 'Flat Fee Commission Rates Starting at ONLY 199.00 Per Transaction',
        body: ['Save your Money with the Lowest Cost Brokerage in the Country', 'Flat Fee Commission Rates Starting at ONLY $199.00 Per Transaction', 'Commission Caps Starting as low as 2k']
    },
    {
        "heading": "Cash Flow 4U Consistent Buyer to Back your Real Estate Investment Deals",
        "body": [
            "Your Buyer Cash Flow 4U will Purchase Any Property Up to 15 Million Dollars",
            "Cash Flow 4U has the Largest Investor Database in the Country"
        ]
    },
    {
        "heading": "Learn to Wholesale & Subject To Transactions",
        "body": [
            "Learn to Wholesale through a Wholesaler with a Real Estate License",
            "Earn Up to Six Commissions on One Transaction",
            "Lean to Leverage other People's Money",
            "Learn to Acquire Cash Flow & Flip Properties through the Subject To Process"
        ]
    },
    {
        "heading": "FREE Deal Analyzer",
        "body": [
            "Learn to Analyze any Deal with the Deal Analyzer Platform FREE!",
            "Analyze Straight Remodels",
            "Analyze a Remodel Down to Studs",
            "Analyze a Remodel & Addition",
            "Analyze a Full Development Project",
            "Analyze a Buy & Hold/ Rental Project",
            "Use the Deal Analyzer to Analyze Your Own Personal Investments"
        ]
    },
    {
        "heading": "Farm Marketing",
        "body": [
            "We Cold Call Seller on Your Behalf up to 3000 Home Owners Per Year in your Designated Area of Choice for FREE!",
            "We Text Sellers",
            "We Email Sellers"
        ]
    },
    {
        "heading": "Free Investor Websites",
        "body": [
            "Investor Website Questionnaire to Gather Investor Info for Your Investor List Organizer",
            "Upload Listed Properties",
            "Upload Sold Properties",
            "Independent Listing Pages for all of Your Listings",
            "Link to BPO Report Ordering Page"
        ]
    },
    {
        "heading": "Free Lofty CRM+ Lofty AI",
        "body": [
            "BPO Tech has Merged with Lofty AI to Power Our Agents",
            "Lofty Previously Chime is the Most Robust CRM on the Market FREE for All Agent Subscribed to Our Brokerage"
        ]
    },
    {
        "heading": "Investor List Organizer",
        "body": [
            "Organize your Investor Portfolio by Investor Capital and Type of Investments",
            "Learn How to Capture New Investors for FREE"
        ]
    },
    {
        "heading": "FREE Customizable Luxury IDX Website",
        "body": [
            "Set Up Your Very Own Luxury Customizable IDX Website to Set Up Buyer Searches"
        ]
    },
    {
        "heading": "MDA Business Program with FREE Coaching & Mentorship for Life",
        "body": [
            "MDA Program with FREE Coaching (Million Dollar Agent)",
            "Learn from Experts Weekly and Prospect Daily",
            "We Mentor you to become a Top Producer"
        ]
    },
    {
        "heading": "Live Power Coaching with Our CEO Joban Brown",
        "body": [
            "Learn Real Estate the Right Way with Live Weekly Trainings From CEO Joban Brown",
            "Investing in Real Estate Don't be a Standard Agent",
            "Becoming a Million Dollar Agent"
        ]
    },
    {
        "heading": "Full Training Center & Training on Contracts",
        "body": [
            "Access our Training Center Unlimited",
            "Learn Real Estate Listing Agreements",
            "Learn the Purchase Contract",
            "Full Video Access Covering the Contract"
        ]
    },
    {
        "heading": "FREE CE Courses (In Applicable States & Agent Tenure Rules Apply)",
        "body": [
            "FREE Continuing Education for Agents for Agents with BPO Homes for 1 Year or more"
        ]
    },
    {
        "heading": "Marketing Center FREE Marketing Designs+Access to BPO Homes Custom Design Team",
        "body": [
            "Marketing Center Access",
            "BPO Homes Designers at Your Service",
            "FREE Downloadable Flyer Templates",
            "FREE Marketing Materials and more"
        ]
    },
    {
        "heading": "FREE Digital Business",
        "body": [
            "NFC (Near Field Communication Digital Business Card)"
        ]
    },
    {
        "heading": "FREE Social Media Advertisement (Coming Soon 2025)",
        "body": [
            "Custom Ads for the Following Platforms",
            "Google Ads",
            "Twitter",
            "Instagram",
            "Facebook"
        ]
    },
    {
        "heading": "Low Cost Transaction Coordinators",
        "body": [
            "Our Internal Transaction Coordinators ONLY $199 Per Transaction"
        ]
    },
    {
        "heading": "Revenue Share (Coming Soon 2025)",
        "body": [
            "Agents to Receive Revenue From Monthly Tech Subscriptions",
            "Agents to Receive Revenue Share of Commission Earned by Agents"
        ]
    }
]
